import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import { ClearTrackServiceBaseUrl } from '../../../constants/constants';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import axios from 'axios';
import { statusOption, licenseTrackingFilter, smOptions, groupByOptions, filterConfig, type } from './config';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import { withUserContext } from '../../../contexts/UserContext';
export default withUserContext(class LicenseTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterSearchList: [],
            searchCancelToken: null,
            licenseTrackingFilter: JSON.parse(JSON.stringify({ ...licenseTrackingFilter })),
            licenseFilterList: {
                statusOptions: statusOption,
                licenseesOption: [],
                licensorOptions: [],
                seasonOption: [],
                type: type,
                smOptions: smOptions,
                groupByOptions: groupByOptions,
                showOptions: [],
                showBasedSeasonList: [],
                trackingStatusOptions: []
            },
            clearFilters: this.props?.clearFilters,
            sortBy: "Show",
            isLicensorFetching: false,
            selectedLicensorForSearch: null,
            selectedLicenseeRepForSearch: null,
            selectedShowForSearch: null,
            isLicensorRepFetching: false,
            isShowFetching: false
        }
    }

    componentDidMount() {
        document.title = 'License Tracking'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.getEntity('SEASON', filterConfig.season, 'seasonOption');
        // this.getEntity('USER', filterConfig.user, 'licenseesOption');
        this.fetchStaticData("lu_union", "unionOptions");
        this.fetchStaticData("lu_license_tracking_actions", "trackingStatusOptions");
        this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.getEntity("SEASON", filterConfig.season, "seasonOption");
            this.fetchStaticData("lu_union", "unionOptions");
            this.fetchStaticData("lu_license_tracking_actions", "trackingStatusOptions");
            this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Show" })
        }
        if (this.props?.userContext.dashboardName === "License Tracking" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    getEntity = (entity, config, node) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        label: item[config.name],
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    this.updateListState(formattedList, node, "licenseFilterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }

    getShows = (entity, config, node, searchString) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": searchString,
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            entity === "LICENSOR" ? this.setState({ isLicensorFetching: true }) : entity === "USER" ? this.setState({ isLicensorRepFetching: true }) : entity === "SHOW" ? this.setState({ isShowFetching: true }) : null
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name]
                    }));
                    this.updateListState(formattedList, node, "licenseFilterList");
                    entity === "LICENSOR" ? this.setState({ isLicensorFetching: false }) : entity === "USER" ? this.setState({ isLicensorRepFetching: false }) : entity === "SHOW" ? this.setState({ isShowFetching: false }) : null
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
        this.props.updateListState(null, 'season', 'licenseTrackingFilter')
        this.handleFilterListSelection('season', null, null, 'season')
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/seasonsBasedOnShows?show_id=${newValue.value}`,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    let selectedShow = response?.data[0]?.seasons?.find(
                        (item) => response?.data[0]?.show_id === newValue.value
                    )?.show_season_id;
                    let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                        (item, index, data) =>
                            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                    ) : [];
                    let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                        value: item.season_id,
                        label: item.season_name,
                    })) : [];
                    let seasonBasedEpisodes = response?.data[0]?.seasons;
                    this.setState({ seasonBasedEpisodes })
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "showBasedSeasonList", 'licenseFilterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'licenseFilterList')
                    }
                })
            .catch(error => {
                console.log("error--", error)
            })
    }

    fetchStaticData = (entity, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'licenseFilterList')
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    checkSelectLabel(obj,field){
        if(obj[field]==="Select"){
            obj[field]=null;
        }
    }
    handleFilterListSelection = (type, val, name, node) => {
        let list = this.state.filterSearchList.filter(item => item.type !== type);
        this.setState({ filterSearchList: list });
        let licenseTrackingFilter = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = licenseTrackingFilter.filter((item, index) => {
            if (item.type === type) {
                return licenseTrackingFilter[index] = newFilterValue
            }
        })
        if (check.length === 0) {
            licenseTrackingFilter.push(newFilterValue)
        }
        this.setState({
            filterSearchList: licenseTrackingFilter
        })
        let musicEditorDetails = {
            licensor_search_ids: licenseTrackingFilter.find((item) => item.type === "licensorName")?.value || null,
            tracking_status_search_ids: licenseTrackingFilter.find((item) => item.type === "trackingStatus")?.value || null,
            status_search_ids: licenseTrackingFilter.find((item) => item.type === "status")?.label || null,
            type_search_ids: licenseTrackingFilter.find((item) => item.type === "type")?.value || null,
            licensor_type_search_ids: licenseTrackingFilter.find((item) => item.type === "sOrM")?.label || null,
            group_by_clause: licenseTrackingFilter.find((item) => item.type === "groupBy")?.label || null,
            licensee_rep_search_ids: licenseTrackingFilter.find((item) => item.type === "licenseeRep")?.value || null,
            days_pending_search_text: licenseTrackingFilter.find((item) => item.type === "daysPending")?.label || null,
            show_search_ids: licenseTrackingFilter.find((item) => item.type === "show")?.value || null,
            season_search_ids: licenseTrackingFilter.find((item) => item.type === "season")?.value || null,
            show_year_search_text: licenseTrackingFilter.find((item) => item.type === "showYear")?.label || null,
            episode_title_search_text: licenseTrackingFilter.find((item) => item.type === "episodeName")?.value || null,
            episode_no_search_text: licenseTrackingFilter.find((item) => item.type === "episode")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: this.props?.rowsPerPage,
            page_no: this.props?.page > 0 ? this.props?.page : 1
        }
        this.checkSelectLabel(musicEditorDetails,"type_search_ids");
        this.checkSelectLabel(musicEditorDetails,"licensor_type_search_ids");
        this.checkSelectLabel(musicEditorDetails,"tracking_status_search_ids");
        this.checkSelectLabel(musicEditorDetails,"status_search_ids");
        
        
        
        this.props.updateFilteredList(licenseTrackingFilter, musicEditorDetails, type)
    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'licenseFilterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'licenseFilterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };


    render() {
        return (
            <div className="filterFieldsContainer">
                <MDBRow>
                    <MDBCol md={8}>
                        <MDBRow className='mb-5'>
                            <MDBCol md={12}>Licenses</MDBCol>
                            <MDBCol md={4}>
                                <SearchSelectField
                                    id="licensorName"
                                    placeholder="- Licensor Name -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    loading={this.state.isLicensorFetching}
                                    detail_selected={this.props?.licenseTrackingFilter?.licensorName ? this.state.selectedLicensorForSearch : null}
                                    // detail_selected={this.props?.licenseTrackingFilter?.licensorName ? { value: this.props?.licenseTrackingFilter?.licensorName, text: this.state.licenseFilterList?.licensorOptions.find(item => item.value === this.props?.licenseTrackingFilter?.licensorName)?.text } : null}
                                    options={this.state.licenseFilterList?.licensorOptions || []}
                                    valueSelected={(e, newValue) => {
                                        let selectedShow = this.state.licenseFilterList?.licensorOptions?.find(item => item.value === (newValue != null ? newValue.value : null));
                                        this.props.updateListState(newValue != null ? newValue.value : null, 'licensorName', 'licenseTrackingFilter')
                                        this.setState({ selectedLicensorForSearch: newValue })
                                        this.handleFilterListSelection("licensorName", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "licensorName")
                                    }}
                                    searchText={ev => {
                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                            this.getShows("LICENSOR", filterConfig.licensor, "licensorOptions", ev.target.value);
                                        } else {
                                            this.setState({ isLicensorFetching: false })
                                        }
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3} className="lh-1">
                                <SelectField
                                    id="Type"
                                    value={this.props?.licenseTrackingFilter?.type || null}
                                    placeHolderText="- Type -"
                                    options={this.state.licenseFilterList?.type || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.licenseFilterList?.type?.find(item => item.value === e.target.value);
                                        this.handleFilterListSelection("type", selectedValue?.value, selectedValue?.label, "type");
                                        this.props.updateListState(e.target.value, "type", "licenseTrackingFilter");
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <SearchSelectField
                                    id="licenseRep"
                                    placeholder="- Licensee Rep -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    loading={this.state.isLicensorRepFetching}
                                    detail_selected={this.props?.licenseTrackingFilter?.licenseeRep ? this.state.selectedLicenseeRepForSearch : null}
                                    // detail_selected={this.props?.licenseTrackingFilter?.licenseeRep ? { value: this.props?.licenseTrackingFilter?.licenseeRep, text: this.state.licenseFilterList?.licenseesOption.find(item => item.value === this.props?.licenseTrackingFilter?.licenseeRep)?.text } : null}
                                    options={this.state.licenseFilterList?.licenseesOption || []}
                                    valueSelected={(e, newValue) => {
                                        let selectedValue = this.state.licenseFilterList?.licenseesOption?.find(item => item.value === (newValue != null ? newValue.value : null));
                                        this.props.updateListState(newValue != null ? newValue.value : null, 'licenseeRep', 'licenseTrackingFilter')
                                        this.setState({ selectedLicenseeRepForSearch: newValue })
                                        this.handleFilterListSelection("licenseeRep", (newValue != null ? selectedValue?.value : null), newValue != null ? selectedValue?.text : null, "licenseeRep")
                                    }}
                                    searchText={ev => {
                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                            this.getShows('USER', filterConfig.user, 'licenseesOption', ev.target.value);
                                        } else {
                                            this.setState({ isLicensorRepFetching: false })
                                        }
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mb-5">
                            <MDBCol md={4}>
                                <SelectField
                                    id="trackingStatus"
                                    value={this.props.licenseTrackingFilter?.trackingStatus}
                                    options={this.state.licenseFilterList?.trackingStatusOptions || []}
                                    placeHolderText="- Last Lic. Tracking Status -"
                                    onChange={(e) => {
                                        let selectedValue = this.state.licenseFilterList?.trackingStatusOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'trackingStatus', 'licenseTrackingFilter')
                                        this.handleFilterListSelection('trackingStatus', selectedValue?.value, selectedValue?.label, 'trackingStatus')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id="s/m"
                                    value={this.props.licenseTrackingFilter?.sOrM || ""}
                                    options={this.state.licenseFilterList?.smOptions || []}
                                    placeHolderText="- S/M -"
                                    onChange={(e) => {
                                        let selectedValue = this.state.licenseFilterList?.smOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'sOrM', 'licenseTrackingFilter')
                                        this.handleFilterListSelection('sOrM', selectedValue?.value, selectedValue?.value === 3 ? 'SM' : selectedValue?.label, 'sOrM')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicTextField
                                    id="days"
                                    placeholder="- Days Pending -"
                                    value={this.props?.licenseTrackingFilter?.daysPending || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('daysPending', 'daysPending', e.target.value, 'daysPending');
                                        this.props.updateListState(e.target.value, 'daysPending', 'licenseTrackingFilter');
                                    }
                                    }
                                    onKeyUp={(e) =>{
                                        if(e.keyCode === 13){
                                            this.props?.handleFilterSearch()
                                        }
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="lh-2rem">
                            <MDBCol md={3}>
                                <SelectField
                                    id="status"
                                    placeHolderText="- Status -"
                                    value={this.props.licenseTrackingFilter?.status || ""}
                                    options={this.state.licenseFilterList?.statusOptions || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.licenseFilterList?.statusOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'status', 'licenseTrackingFilter')
                                        this.handleFilterListSelection('status', selectedValue?.value, selectedValue?.label, 'licenseTrackingFilter')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={3}>
                                {/* <SelectField
                                    id="groupBy"
                                    placeHolderText="- Group By -"
                                    value={this.props.licenseTrackingFilter?.groupBy || ""}
                                    options={this.state.licenseFilterList?.groupByOptions || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.licenseFilterList?.groupByOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'groupBy', 'licenseTrackingFilter')
                                        this.handleFilterListSelection('groupBy', selectedValue?.value, selectedValue?.label, 'groupBy')
                                    }}
                                /> */}
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={4}>
                        <MDBRow>
                            <MDBCol md={12}>Show</MDBCol>
                            <MDBCol md={6}>
                                <SearchSelectField
                                    id="show"
                                    placeholder="- Show -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    loading={this.state.isShowFetching}
                                    detail_selected={this.props?.licenseTrackingFilter?.show ? this.state.selectedShowForSearch : null}
                                    // detail_selected={this.props?.licenseTrackingFilter?.show ? { value: this.props?.licenseTrackingFilter?.show, text: this.state.licenseFilterList?.showOptions.find(item => item.value === this.props?.licenseTrackingFilter?.show)?.text } : null}
                                    options={this.state.licenseFilterList?.showOptions || []}
                                    valueSelected={(e, newValue) => {
                                        if (newValue) {
                                            this.setState({ selectedShowForSearch: newValue })
                                            this.selectedShowBasedSeason(newValue)
                                            this.props.updateListState(newValue != null ? newValue.value : null, 'show', 'licenseTrackingFilter')
                                            this.handleFilterListSelection("show", (newValue != null ? newValue?.value : null), newValue != null ? newValue?.text : null, "show")
                                        } else {
                                            this.setState({ selectedShowForSearch: null }, () => {
                                                if (!this.state.selectedShowForSearch) {
                                                    this.props.updateListState(null, 'season', 'licenseTrackingFilter')
                                                    this.handleFilterListSelection('season', null, null, 'season')
                                                    this.props.updateListState(null, 'episodeName', 'licenseTrackingFilter')
                                                    this.handleFilterListSelection('episodeName', null, null, 'episodeName')
                                                }
                                            })
                                            this.props.updateListState(null, 'show', 'licenseTrackingFilter')
                                            this.handleFilterListSelection("show", null, null, "show")

                                        }
                                    }}
                                    searchText={ev => {
                                        // dont fire API if the user delete or not entered anything
                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                            this.getShows("SHOW", filterConfig.show, "showOptions", ev.target.value);
                                        } else {
                                            this.setState({ isShowFetching: false })
                                        }
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={6} className="lh-1">
                                <SelectField
                                    id="episode"
                                    // label={"Seasons"}
                                    placeHolderText="- Episode Name -"
                                    options={this.props?.licenseTrackingFilter?.season ? this.state.licenseFilterList?.seasonBasedEpisodeList : this.state.licenseFilterList?.episodeOption || []}
                                    width="80%"
                                    value={this.props?.licenseTrackingFilter?.episodeName || null}
                                    onChange={(e) => {
                                        let selectedValue = (this.props?.licenseTrackingFilter?.season ? this.state.licenseFilterList?.seasonBasedEpisodeList : this.state.licenseFilterList?.episodeOption)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'episodeName', 'licenseTrackingFilter')
                                        this.handleFilterListSelection('episodeName', selectedValue?.value, selectedValue?.label, 'episodeName')
                                    }}
                                />
                                {/* <BasicTextField
                                    id="episodeName"
                                    placeholder="- Episode Name -"
                                    value={this.props?.licenseTrackingFilter?.episodeName || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('episodeName', 'episodeName', e.target.value, 'episodeName');
                                        this.props.updateListState(e.target.value, 'episodeName', 'licenseTrackingFilter');
                                    }
                                    }
                                /> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow >
                            <MDBCol md={3}>
                                <SelectField
                                    id="season"
                                    options={this.props?.licenseTrackingFilter?.show ? this.state.licenseFilterList?.showBasedSeasonList : this.state.licenseFilterList?.seasonOption || []}
                                    placeHolderText="- Season -"
                                    value={this.props?.licenseTrackingFilter?.season || null}
                                    onChange={(e) => {
                                        let selectedValue = (this.props?.licenseTrackingFilter?.show ? this.state.licenseFilterList?.showBasedSeasonList : this.state.licenseFilterList?.seasonOption)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'season', 'licenseTrackingFilter')
                                        this.handleFilterListSelection('season', selectedValue?.value, selectedValue?.label, 'season')
                                        let selectedShowSeason =
                                            this.state.seasonBasedEpisodes?.find(
                                                (item) => item.season_id === e.target.value
                                            )?.show_season_id;
                                        this.setState(
                                            { show_season_id: selectedShowSeason },
                                            () => {
                                                this.getShowBasedEpisodes(this.state.show_season_id);
                                            }
                                        );
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    id="showYr"
                                    placeholder="- Show Yr -"
                                    value={this.props?.licenseTrackingFilter?.showYear || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('showYear', 'showYear', e.target.value, 'showYear');
                                        this.props.updateListState(e.target.value, 'showYear', 'licenseTrackingFilter');
                                    }
                                    }
                                    onKeyUp={(e) =>{
                                        if(e.keyCode === 13){
                                            this.props?.handleFilterSearch()
                                        }
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>

                                <BasicTextField
                                    id="episode#"
                                    placeholder="- Episode # -"
                                    value={this.props?.licenseTrackingFilter?.episode || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('episode', 'episode', e.target.value, 'episode');
                                        this.props.updateListState(e.target.value, 'episode', 'licenseTrackingFilter');
                                    }
                                    }
                                    onKeyUp={(e) =>{
                                        if(e.keyCode === 13){
                                            this.props?.handleFilterSearch()
                                        }
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}></MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div >
        )
    }
})