import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./ShowSeasonSong.scss";
import {
  groupList,
  allContactList,
  initialLicenseTracking,
} from "../Reports/config";
import clearTrackService from "../../../services/service";
import ShowSeason from "./ShowSeason";
import * as Constants from "../../../constants/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withUserContext } from "../../../contexts/UserContext";
import Chip from "@material-ui/core/Chip";

class LicenseTracking extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: groupList,
      licenseTracking: initialLicenseTracking,
      licensorsOption: [],
      licenseesOption: [],
      seasonOption: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: [],
      options: [],
      removedItem: null,
      optionIdValues: [],
      isPosting: false,
      isClearFlag: false,
      found: [],
      rightOption: [],
      showOption:[],
      selectedType: null,
      sortArray: [],
      licenseTypeOptions:[
        { text: "S", value: "S" },
        { text: "M", value: "M" },
        { text: "SM", value: "SM" },
      ],
      isPosting: false,
      isLoading: false,
      isGroupOrderError: false,
      searchLicensorText:'',
      searchLicenseeRepText:'',
    };
  }

  shortingUpGroupList = (index) => {
    let sortArrayDetails = [...this.state.sortArray];
        let nextIndex = index + 1;
        let previousIndex = index - 1;
        var sortItem = sortArrayDetails[index];
        sortArrayDetails.splice(index, 1);
        if (index === 0) {
            sortArrayDetails.splice(nextIndex, 0, sortItem);
        } else {
            sortArrayDetails.splice(previousIndex, 0, sortItem);
        }
      this.setState({ sortArray: sortArrayDetails });
  };

  shortingDownGroupList = (index) => {
    let sortArrayDetails = [...this.state.sortArray];    let nextIndex = index + 1;
    var sortItem = sortArrayDetails[index];
    sortArrayDetails.splice(index, 1);
    sortArrayDetails.splice(nextIndex, 0, sortItem);
    this.setState({ sortArray: sortArrayDetails });
  };

  removeSortItem = (index,item) =>{
    console.log("item",item)
    let sortDeleteArray = [...this.state.sortArray]
    sortDeleteArray.splice(index,1)
    this.setState({sortArray: sortDeleteArray})
  }

  // handleSubmit = () => {
  //   console.log("submit", this.state.licenseTracking);
  // };

  componentDidMount() {
    let list = JSON.parse(JSON.stringify([...groupList]))
    this.setState((prevState) => ({
      licenseTracking: {
        ...prevState.licenseTracking,
        ['is_episodic']:'is_episodic',
        ['is_pending'] : true,
        ['is_overdue'] : true,
        ['is_sync'] : true,
        ['is_master'] : true,
        ['is_sync_master'] : true,
        ['license_received_date'] :true,
        ['is_license_request'] :true
      },
      sortArray:[list[0]],
      config: list
    }));
    this.fetchLicenseType();
    this.fetchLicensorsData();
    this.fetchLicenseeRepData();
  }

  fetchLicenseType = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_license_type`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.id, text: item.name }));
        this.setState({ licenseOptions: formattedList });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err)
        })
  }

  fetchLicensorsData = (searchString) => {
    this.setState({ isLoading: true });
    const searchParam = searchString ? `&searchString=${searchString}` : '';
    clearTrackService.getData(
      `${Constants.ClearTrackServiceBaseUrl}/entitySearch?entity=LICENSOR${searchParam}`,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.licensor_id, text: item.licensor_name }));
        this.setState({ licensorsOption: formattedList });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  fetchLicenseeRepData = (searchString) => {
    this.setState({ isLoading: true });
    clearTrackService.getData(
      `${Constants.ClearTrackServiceBaseUrl}/userAll?searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id)
    .then(response => {
        let validRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)', 'Licensing Admin']
        let validUsers = []
                    for(let i = 0; i< response?.data?.length; i++) {
                    for(let j = 0; j < response?.data[i]?.tenants?.length ;  j++) {
                        if(response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
                        if(response?.data[i]?.tenants[j]?.division_role?.some(item => validRoles?.includes(item?.role_name))) {
                            validUsers.push({
                                        value:  response?.data[i].user_id,
                                        text:  response?.data[i].full_name,
                                        is_active: response?.data[i].is_active,
                                    }) 
                        }
                        }
                    }
                    }
        this.setState({ licenseesOption: validUsers});
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  // 
  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }

  setSeasonId = (seasonIdValues) => {
    let seasonIdValue = this.state.seasonIdValues;
    seasonIdValue = seasonIdValues;
    this.setState({
      seasonIdValues: seasonIdValue
    })
  }

  setShowId = (showIdValues) => {
    let showIdValue = this.state.showIdValues;
    showIdValue = showIdValues;
    this.setState({
      showIdValues: showIdValue
    })
  }
  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
      let splitVal = this.state.removedItem.split(":");
      let name = splitVal[0].trim();
      let valueId = splitVal[1].trim();
      if (name === "Season") {
        let filterSeasonIdValues = this.state.found.find(item => item.label === valueId).value
        let index2 = this.state.seasonIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.seasonIdValues
        id.splice(index2, 1);
        this.setState({seasonIdValues: id})
      }
      else if (name === 'Show') {
        let filterSeasonIdValues = this.state.showOption.find(item => item.text === valueId).value
        let index2 = this.state.showIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.showIdValues
        id.splice(index2, 1);
        this.setState({showIdValues: id})
      }
      else if (name === "Options") {
        let filterSeasonIdValues = this.state.rightOption.find(item => item.text === valueId).value
        let index2 = this.state.optionIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.optionIdValues
        id.splice(index2, 1);
        this.setState({optionIdValues: id})
      }
    });
  }

  seasonValues = (seasonValue) => {
    this.setState({found:seasonValue})
  }

  showValues = (showValue) => {
    let backup = this.state.showOption
    backup = backup.concat(showValue)
    this.setState({showOption:backup})
  }

  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = [];
    let list = JSON.parse(JSON.stringify([...groupList]))
    this.setState({ filterValues: values, musicReplacementDomainDetails : [], licenseTracking: initialLicenseTracking, sortArray:[],config: list });
  }
  handleSubmitLicenseTrackingReport = (report) => {
    if(this.state.sortArray.length === 0){
      this.setState({isGroupOrderError: true})
    }else{
      report ? this.setState({isPosting: true}):this.setState({isLoading: true})
      this.setState({ postInitiated: true, isGroupOrderError: false});
      let { licenseTracking,filterValues, seasonIdValues, showIdValues} = this.state;
      let clearance_license_tracking_id = 1;
      let sort_order = this.state.sortArray.map((item)=>item.label)
      let sort_level = this.state.sortArray.map((item)=>item.sortValue)
      let mainTitile_sort_level = this.state.sortArray.map((item)=>item.mainTitleValue)
      let arr = [];
      if(licenseTracking.is_sync){
        arr.push("S")
      }if(  licenseTracking.is_master ){
        arr.push("M")
      }if(licenseTracking.is_sync_master){
        arr.push("S/M")
      }
      console.log("arr",arr)
      let licArray = []
      console.log("payeeCheckReports?.selected_payees", Object.keys(licenseTracking.selected_licensors).length)
      if (Object.keys(licenseTracking.selected_licensors).length > 0) {
        licArray.push(licenseTracking.selected_licensors.map((item)=>item.value))
      }
      let repArray = []
      console.log("payeeCheckReports?.selected_payees", Object.keys(licenseTracking.selected_licensee_rep).length)
      if (Object.keys(licenseTracking.selected_licensee_rep).length > 0) {
        repArray.push(licenseTracking.selected_licensee_rep.map((item)=>item.value))
      }
      let payload = {
        licensor_type: arr,
        sort_level: licenseTracking.is_episodic === "is_main_title" ? mainTitile_sort_level : sort_level,
        is_pending: licenseTracking.is_pending ? 1:0,
        is_overdue: licenseTracking.is_overdue ? 1:0,
        is_closed: licenseTracking.is_closed ? 1:0,
        is_reliance_letters: licenseTracking.is_reliance_letters ? 1:0,
        is_license_received: licenseTracking.is_license_receive ? 1:0,
        license_received_date: licenseTracking.license_received_date ? 1:0,
        is_show_history: licenseTracking.is_show_history ? 1:0,
        is_report_comment: licenseTracking.is_report_comment ? 1:0,
        is_license_request: licenseTracking.is_license_request ? 1:0,
        license_id: licArray[0],
        licensor_rep: repArray[0],
        season_id: seasonIdValues,
        show_id: showIdValues,
        sort_order: sort_order,
        is_episodic: licenseTracking.is_episodic === "is_episodic" ? 1 : 0,
        is_main_title :licenseTracking.is_episodic === "is_main_title" ? 1 : 0
        // show_id: 643,
        // season_id: 643,
        // is_closed: 1,
        // is_reliance_letters: 1,
        // licensor_id: 1
      }
      //   clearance_license_tracking_id
      // }
      this.props.generateReport(this.props?.reportName, payload, "License Tracking Report",report)
      .then((webSocketConn) => {
        webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data);
          if (response?.statusCode == 200) {
            this.setState({ isPosting: false });
            this.props?.downloadUrl(response?.body);
            // this.props?.notificationComponent(true, "success");
            this.setState({isPosting: false, isLoading: false})
          } else {
            // this.props?.notificationComponent(true, "fail");
          }
        };
      }).catch((err) => {
        this.setState({ isPosting: false })
        this.notificationComponent(true, 'fail')
    })
    }
   
  }

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      licenseTracking: {
        ...prevState.licenseTracking,
        [field]: value,
      },
    }));
  };

  handleLicensorSearchChange = (e) => {
    const searchString = e.target.value;
    this.setState({ searchLicensorText: searchString });
    this.fetchLicensorsData(searchString);
  };

  handleLicenseeRepSearch = (e) => {
    const searchString = e.target.value;
    this.setState({ searchLicenseeRepText: searchString });
    this.fetchLicenseeRepData(searchString);    
  };

  GenerateLicenseTrackingDetails = (value) => { 
    this.setState({selectedType: value } )
  };

  addSortItem = () =>{
    let sortArrayRecord = this.state.config.filter((item)=>item.value === this.state.selectedType)
    let backup = this.state.sortArray;
    backup = backup.concat(sortArrayRecord);
    let configBackup = this.state.config
    configBackup.shift(sortArrayRecord)
    console.log("configBackup",configBackup)
     this.setState({ sortArray: backup, config: configBackup})
  }
  render() {
    return (
      <>
        {" "}
        <p>
          <b>License Tracking Report</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "block" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="ModalContainer"
        >
          <MDBRow>
            <MDBCol md={2}>
              <MDBRow  className="episodic-font">
                
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  id="radio-group-height"
                  //defaultValue="is_episodic"
                    value={this.state.licenseTracking?.is_episodic || null}
                    onChange={(e) => this.handleChange("is_episodic", e.target.value)}
                  >
                    <MDBCol md={6} className="radio-font episodic-font"> 
                    <FormControlLabel
                      className="LabelSize"
                      value="is_episodic"
                      control={<Radio size="small" />}
                      label="Episodic"
                    />
                    </MDBCol>
                    <MDBCol md={6} className="radio-font">
                    <FormControlLabel
                      className="LabelSize"
                      value="is_main_title"
                      control={<Radio size="small" />}
                      label="Main Title"
                    />
                    </MDBCol>
                  </RadioGroup>
                  {/* <Radio
                    name="radio-buttons"
                    label="Episodic"
                    checked={
                      this.state.licenseTracking.selectedValue ===
                      "episodic"
                    }
                    onChange={(e) =>
                      this.handleChange("selectedValue", e.target.value)
                    }
                    value="episodic"
                  />
                  <span style={{ fontSize: "10px" }}>Episodic </span> */}
               
                <MDBCol md={6}>
                  {/* <Radio
                    name="radio-buttons"
                    label="Main Title"
                    checked={
                      this.state.licenseTracking.selectedValue ===
                      "mainTitle"
                    }
                    onChange={(e) =>
                      this.handleChange("selectedValue", e.target.value)
                    }
                    value="mainTitle"
                  />
                  <span style={{ fontSize: "10px" }}>Main Title </span> */}
                </MDBCol>
                <br />
                {/* <BasicLabel text="License Type" />
                <SearchSelectField
                  id={"License Type"}
                  placeHolderText={"Sync, Master, Sync/Master"}
                  multiple={true}
                  // value={this.state.licenseTracking?.selected_license_type}
                  value={
                    this.state.licenseTracking?.selected_license_type?.length > 0
                      ? this.state.licenseTracking?.selected_license_type.map(
                        (item) => ({
                          value: item.value,
                          text: item.text,
                        })
                      )
                      : []
                  }
                  // value={{ 'value': this.state.licenseTracking.selected_license_type?.value, 'text': this.state.licenseTracking.selected_license_type?.text } || {}}
                  options={this.state.licenseTypeOptions || []}
                  onChange={(e, value) =>
                    this.handleChange("selected_license_type", value)
                  }
                /> */}
               

                <MDBRow>
                  <MDBCol md={9}>
                    <BasicCheckbox
                      id="Pending (not overdue)"
                      label={"Pending (not overdue)"}
                      checked={this.state.licenseTracking?.is_pending || false}
                      onChange={(e) =>
                        this.handleChange("is_pending", e.target.checked)
                      }
                    />
                    <BasicCheckbox
                      id="Overdue"
                      label={"Overdue"}
                      checked={this.state.licenseTracking?.is_overdue || false}
                      onChange={(e) =>
                        this.handleChange("is_overdue", e.target.checked)
                      }
                    />
                    <BasicCheckbox
                      id="Closed"
                      label={"Closed"}
                      checked={this.state.licenseTracking?.is_closed || false}
                      onChange={(e) =>
                        this.handleChange("is_closed", e.target.checked)
                      }
                    />
                    <BasicCheckbox
                      id="Reliance Letters"
                      label={"Reliance Letters"}
                      checked={
                        this.state.licenseTracking?.is_reliance_letters || false
                      }
                      onChange={(e) =>
                        this.handleChange(
                          "is_reliance_letters",
                          e.target.checked
                        )
                      }
                    />
                  </MDBCol>
                  <MDBCol md={3}></MDBCol>
                </MDBRow>
              </MDBRow>
              
            </MDBCol>

            <MDBCol md={2}>
            <MDBRow>
              {/* <BasicLabel text={"Licensor Type :"} /> */}
              <div className="licenser_type_align">
                  <div>
                  <BasicCheckbox
                      id="sync"
                      label={"Sync"}
                      checked={this.state.licenseTracking?.is_sync || false}
                      onChange={(e) =>
                        this.handleChange("is_sync", e.target.checked)
                      }
                    />
                  </div>
                  <div>
                  <BasicCheckbox
                      id="master"
                      label={"Master"}
                      checked={this.state.licenseTracking?.is_master || false}
                      onChange={(e) =>
                        this.handleChange("is_master", e.target.checked)
                      }
                    />
                  </div>
                  <div>
                  <BasicCheckbox
                      id="sync_master"
                      label={"Sync/Master"}
                      checked={this.state.licenseTracking?.is_sync_master || false}
                      onChange={(e) =>
                        this.handleChange("is_sync_master", e.target.checked)
                      }
                    />
                  </div>
                </div>
              </MDBRow>
              <BasicCheckbox
                id="Show History"
                label={"Show History"}
                checked={this.state.licenseTracking?.is_show_history || false}
                onChange={(e) =>
                  this.handleChange("is_show_history", e.target.checked)
                }
              />
              <BasicCheckbox
                id="Show Report Comment"
                label={"Show Report Comment"}
                checked={this.state.licenseTracking?.is_report_comment || false}
                onChange={(e) =>
                  this.handleChange("is_report_comment", e.target.checked)
                }              />
              <BasicCheckbox
                id="Show License Request Date"
                label={"Show License Request Date"}
                checked={
                  this.state.licenseTracking?.is_license_request || false
                }
                onChange={(e) =>
                  this.handleChange("is_license_request", e.target.checked)
                }
              />
              <BasicCheckbox
                id="Show License Received Date"
                label={"Show License Received Date"}
                checked={
                  this.state.licenseTracking?.license_received_date || false
                }
                onChange={(e) =>
                  this.handleChange("license_received_date", e.target.checked)
                }
              />
              <BasicCheckbox
                id="Only show if license has been received"
                label={"Only show if license has been received"}
                checked={
                  this.state.licenseTracking?.is_license_received || false
                }
                onChange={(e) =>
                  this.handleChange("is_license_received", e.target.checked)
                }
              />
              <BasicCheckbox
                id="Only show internal License Records that have not yet been issue"
                label={
                  "Only show internal License Records that have not yet been issue"
                }
                checked={
                  this.state.licenseTracking?.is_license_records || false
                }
                onChange={(e) =>
                  this.handleChange("is_license_records", e.target.checked)
                }
              />
              <br />
            </MDBCol>
            <MDBCol md={2} className="licenser-field">
              <br />
              <BasicLabel text="Licensors" />
              <SearchSelectField
                id={"All Licensors"}
                placeHolderText={"All Licensors"}
                multiple={true}
                value={
                  this.state.licenseTracking?.selected_licensors?.length > 0
                    ? this.state.licenseTracking?.selected_licensors.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                // value={{ 'value': this.state.licenseTracking.selected_licensors?.value, 'text': this.state.licenseTracking.selected_licensors?.text } || {}}
                options={this.state.licensorsOption || []}
                onChange={(e, value) =>
                  this.handleChange("selected_licensors", value)
                }
                searchText ={this.handleLicensorSearchChange}
                manageInputValue={true} 
                inputValue={this.state.searchLicensorText}              
              // searchSelect={true}
              />
              <div className="licenser-field">
              <BasicLabel text="Licensee Rep" />
              <SearchSelectField
                id={"All Licensee Rep"}
                multiple={true}
                placeHolderText={"All Licensee Rep"}
                value={
                  this.state.licenseTracking?.selected_licensee_rep?.length > 0
                    ? this.state.licenseTracking?.selected_licensee_rep.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                // value={{ 'value': this.state.licenseTracking.selected_licensee_rep?.value, 'text': this.state.licenseTracking.selected_licensee_rep?.text } || {}}
                options={this.state.licenseesOption || []}
                onChange={(e, value) =>
                  this.handleChange("selected_licensee_rep", value)
                }
                searchText={this.handleLicenseeRepSearch}
                manageInputValue={true} 
                inputValue={this.state.searchLicenseeRepText}                
              // searchSelect={true}
              />
              </div>
            </MDBCol>
            <MDBCol md={3}>
              <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                // setSeasonId={this.setSeasonId}
                // setShowId={this.setShowId} 
                filterSeasonIdValues={this.state.seasonIdValues}
                filterShowIdValues={this.state.showIdValues}
                filterValues={this.state.filterValues} 
                reportName={"LicenseTracking"}
                seasonValues={this.seasonValues}
                showValues={this.showValues}/>
                </MDBCol>
            {/* <MDBCol md={2}>
              <br />
              <BasicLabel text="Seasons" />
              <SearchSelectField
                id={"All Seasons"}
                placeHolderText={"All Seasons"}
                value={{ 'value': this.state.licenseTracking.selected_season?.value, 'text': this.state.licenseTracking.selected_season?.text } || {}}
                options={this.state.seasonOption || []}
                onChange={(e, value) => this.handleChange("selected_season", value)}
              />
              <BasicLabel text="Shows" />
              <SearchSelectField
                id={"All Shows in selected season(s)"}
                placeHolderText={"All Shows in selected season(s)"}
                value={{ 'value': this.state.licenseTracking.selected_shows?.value, 'text': this.state.licenseTracking.selected_shows?.text } || {}}
                options={this.state.showOptions || []}
                onChange={(e, value) => this.handleChange("selected_shows", value)}
              />
            </MDBCol> */}
            <MDBCol md={3}>
              <MDBCol md={12}>
                
                <span className="errorText mt-2">{(this.state.isGroupOrderError && this.state.sortArray.length === 0) && "Please select the Group order" }</span>
                <span className="errorText mt-2">{this.state.sortArray.length >= 4 && "Maximum you can add 4 group order values" }</span>
                <br />
                <p> Group By</p>
                {this.state.sortArray.map((item, index) => (
                  <MDBRow>
                    <MDBCol md={9}>
                      <BasicLabel text={item.label} />
                    </MDBCol>
                    <MDBCol md={1}>
                      <MDBIcon
                       icon={(index != 0 && index < (this.state.sortArray.length - 1)) ? 
                        "arrow-down" : ""
                    }
                    size="sm"
                        onClick={() => this.shortingDownGroupList(index)}
                      />
                    </MDBCol>
                    <MDBCol md={1}>
                      <MDBIcon
                         icon={
                          index === 0
                              ? "arrow-down"
                              : "arrow-up"
                      }
                      size="sm"
                        onClick={() => this.shortingUpGroupList(index)}
                      />
                    </MDBCol>
                    <MDBCol md={1}>
                      <MDBIcon icon="times" onClick={()=>this.removeSortItem(index,item)}/>
                    </MDBCol>
                  </MDBRow>
                ))}
                <MDBRow>
                  {this.state.sortArray.length < 4 && <MDBCol md={9}>
                    <SelectField
                      placeHolderText={"- - Select Criteria - -"}
                      //value={this.state.selectedType}
                      options={this.state.config?.filter(item => !this.state.sortArray?.map(ele => ele?.value)?.includes(item?.value))}
                      onChange={(e)=> {
                        let sortArray = [...this.state.sortArray]
                        sortArray.push(this.state.config?.find(item => item?.value === e.target.value))
                        this.setState({sortArray:sortArray})
                      }
                      }
                    />
                  </MDBCol>}
                  <MDBCol md={2}></MDBCol>
                  <MDBCol md={1} className="radio-font">
                    {/* {this.state.sortArray.length <= 4 &&
                      <MDBIcon icon="plus-circle" onClick={this.addSortItem}/>
                    } */}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBCol>
            <MDBCol md={1}></MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol md={8}></MDBCol>
          <MDBCol md={4}>
            <MDBRow>
              <MDBCol id="genexl-btn">
                <br />
                <BasicButton className="genBtn" text={this.state.isLoading ? <CircularProgress color="inherit" size={20} />:"Generate Excel"} onClick={()=>this.handleSubmitLicenseTrackingReport(false)} />
              </MDBCol>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"} onClick={()=>this.handleSubmitLicenseTrackingReport(true)} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} onClick={this.handleClearFilters}/>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <br />
      </>
    );
  }
}

export default withUserContext(LicenseTracking);
